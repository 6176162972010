import { t } from 'i18next';

function selectCamera(facingMode) {
  const constraints = {
    video: {
      facingMode,
    },
  };
  return navigator.mediaDevices.getUserMedia(constraints);
}

export default async function getCamera(facingMode = 'environment') {
  if (!navigator?.mediaDevices?.getUserMedia) throw new Error(t('camera.error_no_camera_support'));

  try {
    const camera = await selectCamera(facingMode);
    if (!camera) throw new Error(t('camera.error_no_camera_found'));
    return camera;
  } catch (error) {
    // no permissions
    if (error?.message?.includes('request is not allowed')) {
      throw new Error(t('camera.error_check_camera_permision'));
    }
    throw error;
  }
}
