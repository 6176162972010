export default {
  oauthServer: 'https://login.blockv.io/vn/2.0.11',
  oauthRedirect: 'https://login.blockv.io/send-event.html',
  serverAddress: 'https://api.bv.vatom.network',
  webSocketServer: 'wss://newws.blockv.io/ws',
  primaryPlatform: 'blockv',
  prefix: 'ddp',
  consentKind: 'blockv.ddp',
  consentVersion: 2,
  appDetails: {
    fqdn: 'blockv.ddp',
    termsAndConditions: 'https://vatom.app/terms-conditions.pdf',
    privacyPolicy: 'https://vatom.app/privacy-policy.pdf',
    campaignDetails: {
      termsAndConditions: 'https://dogedisco.com/terms-and-conditions',
      privacyPolicy: 'https://dogedisco.com/privacy-policy',
    }
  },
  features: {
    map: {
      fqdn: 'blockv.ddp',
      pickupRadius: 300,
    },
    profile: {
      supportEmail: 'Doge Disco Support <support@dogedisco.com>',
      emailSubject: 'Feedback - Doge Disco',
    },
  },
};
