import Blockv from '@blockv/sdk/face';
import Credentials from '../credentials';
import Config from '../config';

export default new Blockv({
  appID: Credentials.appId,
  server: Config.serverAddress,
  websocketAddress: Config.webSocketServer,
  prefix: Config.prefix,
  disableSyncV2: Config.disableSyncV2,
  connectAllPlatforms: false,
});
