import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const materialStyles = makeStyles((theme) => ({
  progress: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ThreeDotLoader(props) {
  const { color = '#333', style } = props;
  const material = materialStyles();
  // class spinner is setup in the index file
  return (
    <>
      {/* Loader component */}
      <div style={style} className="app-spinner">
        <div className={clsx('bounce1', material.progress)} style={{ backgroundColor: color }} />
        <div className={clsx('bounce2', material.progress)} style={{ backgroundColor: color }} />
        <div className={clsx('bounce3', material.progress)} style={{ backgroundColor: color }} />
      </div>
    </>
  );
}
